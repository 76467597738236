import { graphql } from "gatsby";
import React from "react";
import BlockContent from "../components/BlockContent";
import CookieBanner from "../components/CookieBanner";
import Footer from "../components/Footer";
import Nav from "../components/Nav";

const Privacy = ({
  data: {
    sanityPrivacy: { body },
  },
}) => {
  return (
    <>
      <Nav />
      <BlockContent
        blocks={body}
        className="max-w-screen-xl min-h-screen mx-auto mt-16 md:px-page px-4"
        prose
      />
      <Footer />
      <CookieBanner />
    </>
  );
};

export const query = graphql`
  query {
    sanityPrivacy(_id: { regex: "/privacy$/" }) {
      body: _rawBody
    }
  }
`;

export default Privacy;
